@import "../../../../styles/variables";
.label{
    margin-top: 20px;
    margin-bottom: 4px;
  }

.cardContent{
    display: flex;
    gap: 16px;
}

.textarea {
    display: flex;
    align-items: center;
    border: 1px solid $primary-low;
    font-family:sans-serif;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 8px;
    width: 100%;
    min-height: 70px;
    &.--read-only {
      opacity: .6;
      cursor: not-allowed;
    }
  
    &:focus,
    &:active {
      outline: none;
    }
  }
  .productImage{
    height: 235px;
  }