@import '../../styles/_variables.scss';

.modal_wrapper {
    min-width: 550px;
    max-height: 600px;
}
.actionWrapper{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}
.textWrapper{
    // background-color: #2222222A;
    padding: 24px 12px;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 60px;
}
.text{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
}