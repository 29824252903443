.tooltipContainer{
    position: relative;
    display: inline-block;
    &:hover .tooltip{
      display: block;
    }
    cursor: pointer;
    color: #2d00f7;
    font-weight: 500;
  }
  .tooltip{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    display: none;
    z-index: 10;
    width: 250px;
  }
  .infoIcon{
    width: 18px;
    height: 18px;
    margin-top: 4px;
  }
  .eyeIcon{
    position: absolute;
    z-index: 1;
    right: 5px;
    top:55px;
    opacity: 0.5;
    cursor: pointer;
  }