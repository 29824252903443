@font-face {
  font-family: "SF Pro Display";
  src: url("./styles/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./styles/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./styles/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "SF Pro Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.leaflet-container {z-index:0}
.red-pin{
  background-color: red !important;
}

.red-pin::after {
  content: "";
  position: absolute;
  top: 29px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#map{ z-index:1; }
#modal{ z-index:2; }