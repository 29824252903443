@import "../../../styles/variables";



.list_header {
  border-bottom: 1px solid #eeeeee;
}
.list_wrapper {
  min-width: 550px;
}
.formWrapper {
  margin-top: 50px;
}
.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.pickerWrapper {
  margin-top: 5px;
}
.top_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.count_wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
.filterBtn{
  cursor: pointer;
  height: 32px;
  padding: 0 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  min-width: 90px !important;
  background-color: $white;
}
.row{
  display: flex;
}
.filterBtnContainer{
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-end;
}
.filterModal{
  width: 600px;
}
.filterContainer{
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  z-index: 1000;
}
.filterItem{
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
  margin-top: 6px;
}
.filterInput{
  width: 100%;
  margin-top:0px;
}
.button{
  background-color: $white !important;
}