@import '../../styles/_variables.scss';
.steps{
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-bottom: 0px;
    gap: 10px;
}

.stepperContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}
.stepperLine{
    width: 100px;
    height: 1px;
    top: 40px;
    background-color: $status-default;
}

.stepper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: $status-default;
    background-color: $background;
    border: 1px solid $status-default;
    border-radius: 50%;
    &.active{
        background-color: $status-default;
        color: #fff;
        transition: all 0.3s ease-in-out;
    }
}
.stepsNames{
    gap: 75px;
    margin-bottom: 25px;
    padding-top: 10px;

}
.stepName{
    display: flex;
    align-items: center;
    gap: 10px;
    color: $status-default;
}
.wrapper{
    // max-width: 1024px;
    padding: 0 30px;
    // min-width: 800px;
    // width: 100%;
    padding-top: 20px;
}
