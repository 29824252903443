@import "../../../../styles/variables";

.root {
  display: block;
  position: relative;
  background-color: #F9F9F9;
  border: 1.5px solid $primary-low;
  box-sizing: border-box;
  border-radius: $border-radius;
  margin: 3px 0;
  padding: 8px 8px 12px;
  cursor: pointer;
  transition: .2s;

  @media print {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 1.98in;
    margin: 0;
    padding: 11pt 12pt;
    border: none;
    color: #000;
  }

  &:hover {
    background-color: lighten($color: $gray-2, $amount: 6);
  }
}

.checkbox {
  margin-top: 0;
  display: flex;
  align-items: center;

  input {
    margin-left: 0;

    @media print {
      display: none;
    }
  }

  label {
    font-size: 15px;
    color: #000000;

    @media print {
      font-size: 18pt;
      color: #000;
      // padding-bottom: 14pt;
    }
  }
}

.address {
  font-size: 13px;
  line-height: 16px;
  color: $primary-l;
  margin: 5px 0 0 0;



  @media print {
    font-size: 14pt;
    line-height: 1.1;
    color: #000;
    margin-top: auto;
  }
}

.addressCalc {
  font-size: 13px;
  line-height: 16px;
  color: $primary-l;
  margin: 5px 0 0 0;
  width: 65%;



  @media print {
    font-size: 14pt;
    line-height: 1.1;
    color: #000;
    margin-top: auto;
  }
}

.customer {
  font-size: 14px;
  line-height: 16px;
  color: $primary-l;
  margin: 0;

  @media print {
    font-size: 14pt;
    line-height: 1.1;
    color: #000;
    margin-top: auto;
  }
}

.rightElement{
  position: absolute;
  top: 9px;
  right: 6px;
  display: flex;
  align-items: center;
  gap:5px
}

.open{
  transform: rotate(180deg);
}

.high {
  border: 1.5px solid $status-critical !important;
  color: $status-critical !important;
}

.medium {
  border: 1.5px solid $status-warning !important;
  color: $status-warning !important;
}

.index {
  font-weight:bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color:$primary-color;

  color: $white;

  @media print {
    width: 12pt;
    height: 12pt;
    background-color: transparent !important;
    border: 2pt solid #000;
    font-size: 10pt;
    color: #000;
    margin-top: 8pt;
    margin-right: 10pt;
  }
}

.delivery_time {
  font-size: 13px;
  line-height: 16px;
  color: $primary-l;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;

    @media print {
      width: 14pt;
      height: 14pt;
      filter: brightness(-50%);
    }
  }

  @media print {
    color: #000;
    font-size: 14pt;
  }
}

.time_preference {
  color: #a9a9aa;
  width: 16px;
  height: 16px;
  padding-top: 3px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  @media print {
    display: block;
    padding-top: 20pt;
    padding-bottom: 4pt;
    margin-top: auto;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.listButton{
  display: flex
}

.noteButton {

}
.borderColor{
  border-color: #EF233C;
}

