.leaflet-routing-container{
    display: none;
}
.leaflet-div-icon{
    background-color: transparent;
    border-width: 0;
}
.my-div-icon{
    background-color: #1A6AE5;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    border-width: 0;
    transform: translateY(-30px);
}

.start{
    background-color: #FA7373;
}
.completed_icon{
    background-color: #219653;
}

.completed_icon::after{
    border-color:#219653 transparent transparent transparent !important;
}
.start::after{
    border-color:#FA7373 transparent transparent transparent !important;
}

.high{
    background-color: #E0221D !important;
}
.high::after{
    border-color: #E0221D transparent transparent transparent !important;
}

.medium{
    background-color: #F8B230 !important;
}
.medium::after{
    border-color: #F8B230 transparent transparent transparent !important;
}

.low{
    background-color: #1A6AE5 !important;
}
.low::after{
    border-color: #1A6AE5 transparent transparent transparent !important;
}

.my-div-icon::after {
    content: "";
    position: absolute;
    top: 29px;
    border-width: 5px;
    border-style: solid;
    border-color: #1A6AE5 transparent transparent transparent;
  }