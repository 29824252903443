$primary: #222222;
$primary-mid: #acacac;
$primary-l: #8c8b8b;
$primary-low: #dbdbdb;
$background: #fbf8fd;
$panel-header-bg: #ECECF4;
$black: #000;
$white: #fff;
$sidebar-bg: #4000FF;
$sidebar-active-bg: #ffffff;
$error: #ff3b24;

$iris-60: #a5a6f6;
$iris-100: #5d5fef;

$green-1: #219653;
$green-2: #27ae60;
$green-3: #81f13c;
$highlight: #ab20fd;
$status-default: #4000FF;
$primary-color: #1A6AE5;
// $status-default: #200589;
$status-critical: #E0221D;
$status-warning: #F8B230;

$fuschia-80: #f178b6;
$dander: #EB5757;
$blue-2: #023e61;

$gray-2: #dde2e5;
$gray-4: #bdbdbd;
$gray-6: #f8f6f3;

$border-radius: 8px;

$height-lg: 45px;
$height-md: 40px;
$height-sm: 35px;
