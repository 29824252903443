.root {
  max-width: 480px;
  flex-basis: 480px;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.remember {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  text-decoration: none;

  color: #0068ed;
}

.signup {
  margin: 50px 0;
  text-align: center;
}

.eyeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:32px;
  opacity: 0.5;
  cursor: pointer;
}
