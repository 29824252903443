@import "../../styles/variables";

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title_wrapper {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 28px;
}

.subtitle {
  color: $primary-l;
  margin-left: 70px;
}

.buttons {
  display: flex;

  .complete_btn {
    margin-right: 26px;
  }
}

.content {
  display: flex;
  margin-top: 34px;
}

.main_content {
  flex-grow: 1;
  padding-right: 10px;
}

.wrongAddress{
  color: #EF233C;
  font-size: 13px;
}

.drugs {
  flex-basis: 340px;
  flex-grow: 0;
}

.info {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
  }

  &.--borderless {
    border-bottom: none;
  }
}
.horizont {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}


.identification_card {
  position: absolute;
  top: 26px;
  right: 0;
  opacity: 1;
  transition: .2s;

  &:hover {
    opacity: .5;
  }
}

.insurance {
  display: flex;
  align-items: center;
  height: 48px;

  &__logo {
    max-width: 56px;
    margin-right: 20px;
  }

  &__edit_wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0 5px;
  }

  &__edit {
    color: $primary-l;
  }
}

.drug {
  position: relative;
  padding-bottom: 14px;


  &:not(:last-child) {
    margin-bottom: 12px;
    border-bottom: 1px solid #c4c4c4;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.drugActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.otc {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.payments {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}

.payment {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 5px;
  }

  &__value {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
  }
}

.notify_action {
  display: block;
  width: 100%;
  margin-top: 20px;
  margin: 0 3px;
  padding: 0;
}
.address_button {
  width: 100%;
  border-width: 0px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  gap: 10px;
}
.delivery_address {
  text-align: left;
}
.list_wrapper {
  min-width: 550px;
  min-height: 200px;
}
.modalDesc {
  margin-top: 50px;
}
.optionWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.container {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
}
.radioButton {
  size: 40px;
}
.radioButtonText {
  margin-left: 5px;
  font-size: larger;
  margin: 4px;
  margin-left: 10px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 40px;
}
.row{
  display: flex;
}
.text{
  color: #000;
  font-weight:600;
  margin-left: 5px;
}
.payment__label{
  color: #000;
  font-weight:500;
  font-size: 18px;
}
.unit_address{
  font-size: 16px;
}

.label {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 17px;
  color: $primary-l;

  margin-bottom: 5px;
}


.times{
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.priority{
  margin-bottom: 12px;
}
.addAddress{
  font-weight: 600;
  margin-top: 10px;
  margin-left: 15px;
  cursor: pointer;
}
.actionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.cancelButton {
  margin-right: 15px;
}
.googleInput{
  margin-top: 2px;
}
.deleteModal{
  width: 450px;
}

.column{
  display: block;
  flex-direction: column;
}
.radioOption {
  cursor: pointer;
  margin-top: 10px;
  padding: 7px;
  display: flex;
  border-radius: 8px;
  border: 1px solid  $gray-2;
}

.card{
  margin-top: 20px;
  padding: 10px 12px;

  border-radius: 3px;
  transition: .2s;
  background-color: desaturate(darken($gray-6, 1), 4);
}

.card:hover {
  background-color: desaturate(darken($gray-6, 7), 8);
}

.item_header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  letter-spacing: .05em;
  margin-bottom: 15px;
}

.row{
  display: flex;
  justify-content: space-between;
}

.notify_to_pay_modal{
  min-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.notify_to_pay_action__checkbox{
  width: 20px;
  height: 20px;
}

.notify_to_pay_action{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.notify_to_pay_actions{
  margin-bottom: 30px;
  margin-top: 30px;
}
.download_invoice{
  color: $status-default;
  font-weight:500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  text-decoration: underline;
}
.qr_code{
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.select_option{
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.priorityColor{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}