@import "../../styles/variables";


.toolt{
  position: absolute;
  z-index: 100;
  width: 120px;
  background-color: $sidebar-bg;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  left: 60px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}
.toolt::after {
  content: "";
  position: absolute;
  top: 35%;
  left: -4px;
  transform: rotate(90deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $sidebar-bg transparent transparent transparent;
}

.root {
  position: fixed;
  
  z-index: 1;
  width: 210px;
  max-width: 210px;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;

  padding: 20px 24px;

  display: flex;
  flex-direction: column;

  background-color: $sidebar-bg;
  // background-color: $gray-6;
  box-shadow: 1px 0px 0px #eeeeee;
  overflow: hidden;

  transition: all 250ms ease-in-out;
  transition-delay: 250ms;

  &.--narrow {
    padding: 20px 12px;
    max-width: 69px;

    .logo {
      margin-left: 10px;
    }

    .text {
      visibility: hidden;
    }

    .menu_item {
      > a {
        padding: 0 11px;
        max-width: 45px;
      }
    }
    

    .submenu {
      max-height: 0;
    }

    .user {
      height: 45px;
      width: 45px;
      padding: 4px;
      justify-content: center;
      margin: 0;

      transition: all 250ms ease-in-out;
      transition-delay: 500ms;

      &__details {
        display: none;
        max-width: 0;
        overflow: hidden;
      }
    }
  }
}

.logo {
  width: 60px;
  height: 60px;
  transform: translateX(-16px);
  transition: all 250ms ease-in-out;
  // transition-delay: 250ms;
  cursor: pointer;
  // filter: invert(100%) sepia(100%) saturate(0%) brightness(80%);

}
.logoActive{
  // width: 150px;
  transition: all 750ms ease-in-out;
  transform: translateX(0);
}

.nav {
  padding: 30px 0;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.nav_padding {
  flex-grow: 1;
  flex-basis: 0;
  max-height: 90px;
}

.logoText{
  color: $sidebar-active-bg;
  font-size: 35px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu_item:hover .tooltiptext {
  visibility: visible;
}
.menu_item {
  width: 100%;

  > a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 35px;
    color: $sidebar-active-bg;
    // color: $primary;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 250ms ease-in-out;
    path[fill="black"],
    circle[fill="black"],
    ellipse[fill="black"],
    path[fill="#222222"] {
      fill: $sidebar-active-bg;
    }
    path[stroke="black"],
    circle[stroke="black"],
    ellipse[stroke="black"],
    path[stroke="#222222"] {
      stroke: $sidebar-active-bg;
    }
    &.active {
      background-color: $sidebar-active-bg;

      color: $sidebar-bg;

      path[fill="black"],
      circle[fill="black"],
      ellipse[fill="black"],
      path[fill="#222222"] {
        fill: $sidebar-bg;
      }

      path[stroke="black"],
      circle[stroke="black"],
      ellipse[stroke="black"],
      path[stroke="#222222"] {
        stroke: $sidebar-bg;
      }
    }
  }

  & + & {
    margin-top: 10px;
  }

  &__icon {
    margin-right: 12px;
  }
}

.submenu {
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 250ms ease-out;

  &__item {
    white-space: nowrap;
    min-width: 130px;
    &:first-child {
      margin-top: 10px;
    }
    margin-left: 30px;

    a {
      display: inline-flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      line-height: 18px;
      padding: 8px 0;
      border-radius: 8px;
      display: flex;
      padding-left: 6px;
      color: $sidebar-active-bg;
      text-decoration: none;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #c4c4c4;
        border-radius: 8px;
        margin-right: 12px;
      }

      &.--active {
        background-color: $sidebar-active-bg;
        color: $sidebar-bg;
        font-weight: 500;
        &::before {
          background-color: $sidebar-bg;
        }
      }
    }
  }

  &.--hidden {
    max-height: 0;
  }
  &.--show {
    max-height: 200px;
  }
}

.user_wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-right: -16px;
  margin-left: -16px;
  padding: 4px 4px 4px 16px;
  border-radius: 48px;
  cursor: pointer;

  transition: all 250ms ease-in-out;
  transition-delay: 500ms;

  &:hover {
    background-color: rgba($color: $white, $alpha: 0.3);
  }

  &:active {
    background-color: $white;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100px;
  }

  &__name {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
    color: $white;
  }

  &__email {
    font-size: 12px;
    line-height: 14px;
    color: $white;
  }

  &__avatar {
    object-fit: cover;
    height: 40px !important;
    width: 40px !important;
    margin-right: 0 !important;
    border-radius: 40px;
  }
}
.sidebar_help{
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: $white;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  background-color: $status-default;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
