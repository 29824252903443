.root {
  max-width: 480px;
  flex-basis: 480px;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  text-decoration: none;

  color: #0068ed;
}

.message {
  margin: 60px auto;
  max-width: 240px;
  display: flex;
  justify-content: center;
}

.eyeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:32px;
  opacity: 0.5;
  cursor: pointer;
}