@import "../../styles/variables";

.root {
  display: flex;
  padding: 5px;
  // padding-bottom: 0;
  border-bottom: 1px solid #eeeeee;
  background-color: $panel-header-bg;
}

.tab {
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  color: $primary-l;
  padding: 15px 25px;
  margin: 0 5px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  &:first-child {
    margin-left: 0;
  }

  &.--active {
    color: $status-default;
    // border-bottom: 2px solid $primary;
    background-color: $white;
  }
}
