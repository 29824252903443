.root {
  max-width: 480px;
  flex-basis: 480px;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  color: #0068ed;
}

.signup {
  margin: 50px 0;
  text-align: center;
}

.done {
  margin: 60px auto;
  max-width: 240px;
  margin-bottom: 20px;
}
